



























import { OrdemDestinacao, OrdemServico } from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { OrdemDestinacaoService, OrdemServicoService } from '@/core/services/residuo';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class RelatorioOD extends PageBase { 

    service = new OrdemDestinacaoService();
    item: OrdemDestinacao = new OrdemDestinacao();
    overlay: boolean = false;

    mounted() {
        const queryFilter = this.$route.query;
        this.overlay = true;
        const filter = this.service.MontaFiltro(queryFilter);

        this.service.ListarTudoFiltro(filter, 'data', 'Receptor')
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err=>{
                    if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                    this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() => {
                this.overlay = false;
            });
    }
}
